import english from 'antd/locale/en_US';
import deutsch from 'antd/locale/de_DE';
import italian from 'antd/locale/it_IT';
import french from 'antd/locale/fr_FR';
import dutch from 'antd/locale/nl_NL';

export default {
  english,
  deutsch,
  italian,
  french,
  dutch
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { CancelButton, PrimaryButton } from '../../components/UIKit';
import { ATTACH_PROP_MGMT_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';
import { CustomTextInput } from '../../components/ReduxFormFields';
import i18n from '../../i18n';
import { email, parseToLowerCase, required } from '../../containers/validation';
import ModalWindow from '../../containers/ModalWindow';
import { getModalsAddEmployeeSelector, getModalsIsLoadingSelector } from '../../redux-store/selectors/modals';
import { Preloader } from '../../components';

const formID = 'attachPropMgmtFormId';

const AttachPropMgmtModalWindow = (props) => {
  const { handleSubmit, pristine, reset } = props;
  const dispatch = useDispatch();

  const isLoading = useSelector(getModalsIsLoadingSelector);
  const modal = useSelector(getModalsAddEmployeeSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ATTACH_PROP_MGMT_MODAL_ID }));

  React.useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);
  return (
    <ModalWindow modalID={ATTACH_PROP_MGMT_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addPropMgmt')}</h5>
      </div>
      {isLoading ? (
        <div className="employee-add-prealoder-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <form
              id={formID}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="propertyManagementEmail"
                  component={CustomTextInput}
                  label={i18n.t('regMail')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, email]}
                  parse={parseToLowerCase}
                  preventAuto
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={formID} disabled={pristine} isSubmit type="send" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AttachPropMgmtModalWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'attachPropMgmtForm'
})(AttachPropMgmtModalWindow);

export default form;

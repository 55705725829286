import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../../../i18n';
import inputDeviceIcon from '../../../../../assets/images/svg/input-device.svg';

const InputDeviceTooltip = ({ inputState = 0, InputStateString }) => (
  <>
    <img src={inputDeviceIcon} alt="Input Device Icon" />
    <div className="sensorData-title">
      <span>{i18n.t('state')}</span>
    </div>
    <div className="sensorData-value">
      <span>{InputStateString || (inputState ? i18n.t('on') : i18n.t('off'))}</span>
    </div>
  </>
);

InputDeviceTooltip.propTypes = {
  inputState: PropTypes.number,
  InputStateString: PropTypes.string
};

export default InputDeviceTooltip;

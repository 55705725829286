import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ModalWindow from '../../containers/ModalWindow';
import { PrimaryButton, CancelButton } from '../../components/UIKit';
import i18n from '../../i18n';
import { ReactComponent as WarningIcon } from '../../assets/images/svg/warning.svg';
import { setPagesSetting } from '../../containers/PagesSettings/slice';
import { closeModalWindow } from '../../containers/ModalWindow/slice';
import { getModals } from '../../redux-store/selectors/modals';

import './index.scss';

export const EXPERT_MODAL_ID = 'expertModeModalId';
export const ADDITIONAL_COST_MODAL_ID = 'additionalCostModalId';

// This modal will show on time after login
export const CommonInfoModalWindow = ({
  modalID,
  isShowEachTime,
  label,
  info = '',
  cancelLabel,
  confirmLabel,
  confirmHandler
}) => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModals)?.[modalID || ADDITIONAL_COST_MODAL_ID]?.data;
  const defaultHandleClose = (confirm) => {
    if (confirm && !isShowEachTime) dispatch(setPagesSetting({ page: 'modals', data: { [modalID]: false } }));
    dispatch(closeModalWindow({ modalID }));
  };

  const defaultHandleConfirm = () => {
    if (!isShowEachTime) dispatch(setPagesSetting({ page: 'modals', data: { [modalID]: false } }));
    dispatch(closeModalWindow({ modalID }));
  };

  return (
    <ModalWindow isModalOver customOnClose={defaultHandleClose} modalID={modalID}>
      <div className="modal-header">
        <WarningIcon />
        <h5 className="modal-title">{i18n.t(modalData?.title ?? label)}</h5>
      </div>

      <div>
        <div className="modal-body">
          {i18n.exists(modalData?.translationKey || info) ? i18n.t(modalData?.translationKey || info) : info}
        </div>
        <div className="modal-footer">
          {cancelLabel && <CancelButton onClickHandler={defaultHandleClose} i18nLabel={cancelLabel} />}
          <PrimaryButton onClickHandler={confirmHandler || defaultHandleConfirm} i18nLabel={confirmLabel || 'ok'} />
        </div>
      </div>
    </ModalWindow>
  );
};

CommonInfoModalWindow.propTypes = {
  modalID: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  isShowEachTime: PropTypes.bool,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmHandler: PropTypes.func
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lineChart: {
    L1: [],
    L2: [],
    L3: []
  },

  smartMeter: {
    currentL1: 0,
    currentL2: 0,
    currentL3: 0
  },
  realTimeData: {
    from: undefined,
    to: undefined
  },
  type: 't',
  from: undefined,
  to: undefined
};

const loadManagementServiceSlice = createSlice({
  name: 'loadManagementServiceSlice',
  initialState,
  reducers: {
    fetchChartDataSuccess(state, action) {
      const { chartData, type, from, to, scaleMs, interval = 10000 } = action.payload;
      const { smartMeter, L1, L2, L3 } = chartData || {};
      state.lineChart.L1 = L1 || [];
      state.lineChart.L2 = L2 || [];
      state.lineChart.L3 = L3 || [];

      state.smartMeter.currentL1 = smartMeter?.currentL1 || 0;
      state.smartMeter.currentL2 = smartMeter?.currentL2 || 0;
      state.smartMeter.currentL3 = smartMeter?.currentL3 || 0;
      state.type = type;
      state.from = from;
      state.to = to;
      state.interval = interval;

      state.realTimeData = initialState.realTimeData;
      state.scaleMs = scaleMs;
    },

    updateChartData(state, action) {
      const { chartData = {}, realTimeData } = action.payload;
      const { smartMeter, L1, L2, L3 } = chartData;

      if (smartMeter) {
        state.smartMeter = smartMeter;
      }

      if (L1 && L2 && L3) {
        state.lineChart.L1.push(...L1);
        state.lineChart.L2.push(...L2);
        state.lineChart.L3.push(...L3);

        state.realTimeData = realTimeData;
      }
    },

    clearChartData() {
      return initialState;
    }
  }
});

export const { fetchChartDataSuccess, updateChartData, clearChartData } = loadManagementServiceSlice.actions;

export default loadManagementServiceSlice.reducer;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { required, minLength, maxLength } from '../../validation';
import { CustomTextInput, CustomSelect } from '../../../components/ReduxFormFields';
import ModalWindow from '../../ModalWindow';
import { CancelButton, PrimaryButton } from '../../../components/UIKit';
import { addEndUsersToPropMgmForm } from '../constants';
import { ADD_END_USERS_TO_PROP_MGMT_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';
import { getDataListCompaniesDataSelector } from '../../../redux-store/selectors/dataList';
import DataList from '../../DataList';

import i18n from '../../../i18n';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

const transformResponse = (response) => {
  const data = response.list
    .filter((user) => user?.my_company?._id && user?.my_company?.name && user?.my_company?.admin)
    .map(({ my_company: { _id, name, admin: adminId } }) => ({ id: _id, _id, name, adminId }));
  return { data };
};

const AddEndUserToPropMgmtWindow = (props) => {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const companies = useSelector(getDataListCompaniesDataSelector);
  const companiesList = useMemo(() => [{ value: 'null', label: i18n.t('NoPVinstaller') }, ...companies.map((item) => ({ value: item.id, label: item.name }))], [companies]);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_END_USERS_TO_PROP_MGMT_MODAL_ID }));

  return (
    <ModalWindow customClass="max-width-550" modalID={ADD_END_USERS_TO_PROP_MGMT_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addSMU')}</h5>
      </div>

      <div>
        <div className="modal-body">
          <form
            id={addEndUsersToPropMgmForm}
            onSubmit={handleSubmit}
            className="m-login__form m-form pop-up-form add-sm-us"
          >
            <DataList
              listID="companies"
              listURL="/users/pv-installer-users"
              params={{}}
              transformResponse={transformResponse}
            />
            <div className="form-group m-form__group input-field">
              <Field
                name="sm_id"
                component={CustomTextInput}
                label={i18n.t('reqSMID')}
                className="m-input"
                validate={[required, minLen3, maxLen50]}
              />
            </div>
            <div className="form-group m-form__group input-field">
              <div className="flags-select-label">{i18n.t('reqPVI')}</div>
              <Field
                name="pv_installer"
                component={CustomSelect}
                placeholder={i18n.t('selectPVI')}
                options={companiesList}
                validate={[required]}
                searchable
              />
            </div>
            <div className="form-group m-form__group input-field">
              <Field
                name="userName"
                component={CustomTextInput}
                label={i18n.t('username')}
                className="m-input"
                autoComplete="off"
                validate={[required]}
                preventAuto
                required
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={handleOnClose} />
          <PrimaryButton
            customButtonClass="ml-2 installer-to-oem-btn"
            form={addEndUsersToPropMgmForm}
            i18nLabel="send"
            isSubmit
          />
        </div>
      </div>

    </ModalWindow>
  );
};

AddEndUserToPropMgmtWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired
  // reset: PropTypes.func.isRequired
};

export default reduxForm({
  form: addEndUsersToPropMgmForm
})(AddEndUserToPropMgmtWindow);

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import i18n from '../../../i18n';

const LoadManagementChartControls = (props) => {
  const {
    replaceFromProps,
    openDatePicker,
    intervalStr,
    scaleType,
    subType,
    location,
    zoom,
    chart
  } = props;

  // if delay is less than 2 intervals, then it is real time
  const realTimeCond = chart?.interval && moment().diff(moment(chart?.realTimeData?.to || chart?.to)) <= chart.interval * 2;

  const disableForwardBtn = realTimeCond || moment(chart?.to).add(chart?.scaleMs, 'ms').isAfter(moment());
  /**
   * @param {number | string} value next scaleType
   */
  const chartControlsHandler = (value) => {
    const diff = moment(chart.to).diff(moment(chart.from));

    switch (value) {
      case 't':
      case 'h':
      case 'd':
      case 'w': {
        const newSearch = { type: value };
        replaceFromProps(`${location.pathname}?${new URLSearchParams(newSearch).toString()}`);
        break;
      }
      case 'back': {
        const from = moment(chart.from).subtract(diff, 'ms');
        const to = moment(chart.to).subtract(diff, 'ms');
        const newSearch = { from: from.toISOString(), to: to.toISOString(), subType: 'range' };
        replaceFromProps(`${location.pathname}?${new URLSearchParams(newSearch).toString()}`);
        break;
      }
      case 'forward': {
        const from = moment(chart.from).add(diff, 'ms');
        const to = moment(chart.to).add(diff, 'ms');

        const newSearch = { from: from.toISOString(), to: to.toISOString(), subType: 'range' };
        replaceFromProps(`${location.pathname}?${new URLSearchParams(newSearch).toString()}`);
        break;
      }
      default: {
        if (typeof value === 'number') {
          const newScale = diff * value;
          const offset = (diff - newScale) / 2;

          // if offset <1h or> 1w do not change the scale
          if (newScale < 3600000 || newScale > 604800000) {
            return;
          }

          const from = moment(chart.from).add(offset, 'ms');
          const to = moment(chart.to).subtract(offset, 'ms');
          const newSearch = { from: from.toISOString(), to: to.toISOString(), subType: 'range' };
          replaceFromProps(`${location.pathname}?${new URLSearchParams(newSearch).toString()}`);

          return;
        }
        throw new Error('Unknown value');
      }
    }
  };

  return (
    <div className="container-fluid chart-controls-wrapper">
      <div className="row justify-content-between">
        <div className="col-auto">
          <button
            onClick={chartControlsHandler.bind(null, 't')}
            className={
              [subType, scaleType].includes('t')
                ? 'btn-today navig-btn-dashb navig-btn-dashb-act'
                : 'btn-today navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('today')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'h')}
            className={
              subType !== 'd' && [subType, scaleType].includes('h') ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('h')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'd')}
            className={
              [subType, scaleType].includes('d') ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('d')}
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'w')}
            className={
              [subType, scaleType].includes('w') ? 'navig-btn-dashb navig-btn-dashb-act' : 'navig-btn-dashb'
            }
            type="button"
          >
            {i18n.t('w')}
          </button>
        </div>
        <div className="col-auto mr-auto d-flex align-items-start">
          {zoom && (
            <>
              <button
                onClick={chartControlsHandler.bind(null, 0.5)}
                type="button"
                className="navig-btn-dashb btn-plus-zoom-dashb"
                disabled={scaleType === 'h'}
              >
                +
              </button>
              <button
                onClick={chartControlsHandler.bind(null, 2)}
                type="button"
                className="navig-btn-dashb btn-min-zoom-dashb"
                disabled={scaleType === 'w'}
              >
                -
              </button>
            </>
          )}
          {realTimeCond && (
            <div className="liveDataStatus">
              <div className="recIco" />
            </div>
          )}
        </div>
        <div className="col-auto d-flex align-items-center">
          <button
            onClick={openDatePicker}
            type="button"
            className="navig-btn-dashb btn-calendar-dashb"
          >
            <i className="flaticon-event-calendar-symbol fa" />
          </button>
          <span className="date-dashb">{intervalStr}</span>
          <button
            onClick={chartControlsHandler.bind(null, 'back')}
            type="button"
            className="navig-btn-dashb btn-plus-zoom-dashb"
          >
            <i className="la-angle-left la" />
          </button>
          <button
            onClick={chartControlsHandler.bind(null, 'forward')}
            disabled={disableForwardBtn}
            style={{
              cursor: disableForwardBtn ? 'not-allowed' : 'pointer',
              opacity: disableForwardBtn ? '0.5' : '1',
              cursorEvents: disableForwardBtn ? 'none' : 'auto'
            }}
            type="button"
            className="navig-btn-dashb btn-min-zoom-dashb"
          >
            <i className="la-angle-right la" />
          </button>
        </div>
      </div>
    </div>
  );
};

LoadManagementChartControls.propTypes = {
  scaleType: PropTypes.string,
  location: PropTypes.instanceOf(Object).isRequired,
  replaceFromProps: PropTypes.func.isRequired,
  openDatePicker: PropTypes.func.isRequired,
  zoom: PropTypes.bool.isRequired,
  intervalStr: PropTypes.string,
  subType: PropTypes.string,
  chart: PropTypes.instanceOf(Object)
};

export default LoadManagementChartControls;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';

const RenderSmId = ({ original }) => {
  const { _id, gateway } = original || {};
  const selectedSystemsId = useSelector((state) => state.productionAnalysis.selectedSystems);

  return (
    <>
      {selectedSystemsId.includes(_id) && gateway?.color && (
        <Box
          sx={{ width: '40px', height: '3px', backgroundColor: gateway.color, marginRight: '15px' }}
        />
      )}
      {gateway?.sm_id ? gateway.sm_id : i18n.t('noSolarManager')}
    </>
  );
};

RenderSmId.propTypes = {
  original: PropTypes.instanceOf(Object)
};

export default RenderSmId;

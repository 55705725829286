import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../api_helper';
import { settingPrGetUserSucc } from '../../containers/SettingProfile/slice';
import { addNotification } from '../../containers/NotificationGenerator/slice';
import { fetchLoggedInUserSuccess, fetchUserForViewSuccess } from './slice';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getUserFromQuery: builder.query({
      providesTags: ['userQuery'],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
      query: ({ userID, mySelf }) => ({
        url: mySelf
          ? '/user/me?isWeb=true'
          : `/users/solar-managers-users/${userID}`,
        method: 'get'
      }),

      onQueryStarted: async (queryArgs, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(settingPrGetUserSucc({ initialData: data, myself: queryArgs.mySelf }));

          if (queryArgs.mySelf) {
            dispatch(fetchLoggedInUserSuccess(data));
          } else {
            dispatch(fetchUserForViewSuccess(data));
          }
        } catch (error) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    }),
    getUserBySMId: builder.query({
      providesTags: ['userQuery'],
      refetchOnMountOrArgChange: true,
      query: ({ sm_id }) => ({
        url: `/gateways/sm-id/${sm_id}/owner`,
        method: 'get'
      })
    })
  })
});

export const {
  useGetUserFromQueryQuery,
  useGetUserBySMIdQuery
} = userApi;

/* eslint-disable space-before-function-paren */

/**
 * @param {number} bytes
 * @returns {string} formatted bytes
 * */
export const transformBytesToNiceSize = (bytes) => {
  const units = ['bytes', 'KB', 'MB', 'GB'];
  let index = 0;
  let n = parseInt(bytes, 10) || 0;

  while (n >= 1024) {
    n /= 1024;
    index += 1;
  }
  return (`${n.toFixed(n < 10 && index > 0 ? 1 : 0)} ${units[index]}`);
};

/**
 * @param {array} files - array of files
 * @param {string} fieldName
 * @returns {number} - sum of files size
 * */
export const getSize = (files, fieldName = 'size') => files.reduce((acc, file) => acc + file[fieldName], 0);

/**
 *  add files to simple queue for downloading
 * */
const resolver = () => {
  let promise = Promise.resolve();
  return (func, ...properties) => {
    promise = promise.then(() => func(...properties));
  };
};

const addToQueue = resolver();

/**
 * @param {string} url - url of file
 * @returns {Promise<*>} - promise with file content
 * */
const downloadFileByUrl = (url) => new Promise((res) => {
  setTimeout(() => {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
    res();
  }, 500);
});

/**
 * @param {string} url - url of file
 * @returns {func} -add file to queue
 * */
export const download = (url) => addToQueue(downloadFileByUrl, url);

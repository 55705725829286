import React from 'react';
import PropTypes from 'prop-types';

import LoadManagementBarChart from './LoadManagementBarChart';
import LoadManagementLineChart from './LoadManagementLineChart';

import '../index.scss';

const LoadManagementDashboardContainer = (props) => {
  const { zoomHandler, houseFuse, chartData, user, loading = false } = props;
  const arrayForSelectMinMax = [chartData.lineChart?.L1, chartData?.lineChart.L2, chartData?.lineChart.L3].flat().map((item = []) => item[1]);
  const yAxisMin = Math.floor(Math.min(...arrayForSelectMinMax || 0) / 10) * 10;
  const yAxisMax = Math.ceil(Math.max(...arrayForSelectMinMax || 0) / 10) * 10;

  return (
    <div className="load-management-dashboard-container">
      <LoadManagementBarChart
        chartData={chartData}
        houseFuse={houseFuse}
        yAxisMax={yAxisMax}
        yAxisMin={yAxisMin}
      />
      <LoadManagementLineChart
        zoomHandler={zoomHandler}
        chartData={chartData}
        houseFuse={houseFuse}
        yAxisMax={yAxisMax}
        yAxisMin={yAxisMin}
        loading={loading}
        timezone={user.gateway.timeZone}
      />
    </div>
  );
};

LoadManagementDashboardContainer.propTypes = {
  zoomHandler: PropTypes.func.isRequired,
  houseFuse: PropTypes.number.isRequired,
  chartData: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool
};

export default LoadManagementDashboardContainer;

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

/**
 * Render function for React-Table. Column for affected devices.
 * @memberof module:ReactTable
 * @param {Object} props - General props object
 * @param {Object} props.original - object with properties to render
 * @param {Object[]} props.original.affectedDevices - Array of affected devices
 */
const RenderAffectedDevices = ({ original: { affectedDevices, controlled_devices } }) => (
  Array.isArray(affectedDevices || controlled_devices) ? ((affectedDevices || controlled_devices).map(({ _id, device_group, tag }) => (
    <p style={{ lineHeight: 1.5, margin: 0 }} key={_id}>
      {tag ? tag.name : i18n.t(device_group)}
    </p>
  ))) : null
);

RenderAffectedDevices.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired,
  affectedDevices: PropTypes.instanceOf(Array)
};

export default RenderAffectedDevices;

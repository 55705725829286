import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { createStructuredSelector } from 'reselect';

import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/slice';
import {
  addChoosedCompany,
  monitoringReplaceSelected,
  employeeSendRequest,
  saveAllDataListUsers,
  setMonitoringFilters
} from './slice';
import { EDIT_NOTE_MODAL_ID, modalWindowSend } from '../ModalWindow/slice';
import EditNoteWindow from './components/EditNoteWindow';
import { TopBar, Pagination } from '../../components';
import Changer from './components/Changer';
import { MainMonitoringFilterV2 } from './components/MainMonitoringFilterV2';
import ExpandedRow from './components/ExpandedRow';
import { DataListPagination } from '../DataList';
import columns from './columns';
import { ERROR_MONITORING_TICKETS_URL, ERROR_MONITORING_TICKET_URL, USERS_EMPLOYEE_URL } from '../../api/apiUrls';
import { DATA_LIST_ID, EMPLOYEE_LIST_ID } from './constants';
import SemiTabs from './components/SemiTabs';
import { companyUserIdSelector } from './selectors';
import { visibleWebSelector } from '../Users/selectors';
import { getSignInUserOem_IdSelector } from '../../redux-store/selectors/signIn';
import { getDataListMonitoringDataSelector } from '../../redux-store/selectors/dataList';
import {
  getMonitoringDataSelector,
  getMonitoringEmployeesSelector,
  getMonitoringSelectedRowsSelector
} from '../../redux-store/selectors/monitoring';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { parseChoosedElement, parseStatusOfTicket, transformResponse } from './utils';
import Breadcrumbs from '../../components/Breadcrumbs';
import { ChangeColumnsModalWindow } from '../../modals';
import { ReactTableV2 } from '../../components/ReactTableV2';

import './index.scss';

const SubComponentWrapper = (rowData) => <ExpandedRow rowData={rowData} />;

/**
 * Renders Monitoring page
 * @memberof module:Monitoring
 */
class Monitoring extends Component {
  constructor(props) {
    super(props);
    const { myRoleType, pagesSettings } = this.props;

    this.state = {
      columns: columns(myRoleType, pagesSettings[DATA_LIST_ID])
    };
    this.defaultParams = pagesSettings[DATA_LIST_ID].queries;
  }

  componentDidMount() {
    const {
      setMonitoringFilters: setMonitoringFilterFields
    } = this.props;

    /**
     * Load saved filters from local storage
     */
    const monitoringFilter = JSON.parse(localStorage.getItem('monitoringFilter'))?.selectedFields || {};

    setMonitoringFilterFields({ fields: monitoringFilter });
  }

  componentDidUpdate(prevProps) {
    const {
      saveAllDataListUsers: saveAllDataListUsersProps,
      allDataOnPage = [],
      allData = [],
      myRoleType,
      pagesSettings: { [DATA_LIST_ID]: columnsNextSettings }
    } = this.props;
    const {
      allDataOnPage: prevAllDataOnPage,
      pagesSettings: { [DATA_LIST_ID]: columnsPrevSettings }
    } = prevProps;

    if (allDataOnPage && prevAllDataOnPage && !isEqual(prevAllDataOnPage, allDataOnPage)) {
      const newArr = allDataOnPage.filter(({ _id }) => !allData.find(({ _id: idArr1 }) => _id === idArr1));
      saveAllDataListUsersProps({ dataListUsers: newArr });
    }

    const isChangeInstallationFinishedOption = columnsNextSettings.queries.isInstallationCompleted !== columnsPrevSettings.queries.isInstallationCompleted;

    if (!isEqual(columnsPrevSettings.columns, columnsNextSettings.columns || isChangeInstallationFinishedOption)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ columns: columns(myRoleType, columnsNextSettings) });
    }
  }

  /**
   * Form and send request to get all installers of choosed company
   * @param {Array} arrOfChoosedSides array with all choosed rows with checkbox
   */
  formAndSendRequest = (arrOfChoosedSides) => {
    const {
      allData,
      choosedEmployees = {},
      employeeSendRequest: employeeSendRequestProps,
      addChoosedCompany: addChoosedCompanyProps
    } = this.props;

    const listURL = USERS_EMPLOYEE_URL;
    const userIdArray = parseChoosedElement(allData, arrOfChoosedSides, 'user');
    const companyIdArray = parseChoosedElement(allData, arrOfChoosedSides, 'company');
    const companyStatusArray = parseStatusOfTicket(allData, arrOfChoosedSides, 'status');

    addChoosedCompanyProps({ choosedCompany: companyIdArray, choosedUser: userIdArray, choosedStatus: companyStatusArray });

    const params = {
      _limit: 50,
      _start: 0,
      _sort: 'first_name:asc',
      company: companyIdArray[0]
    };

    if (Array.from(companyIdArray).length === 1 && !companyIdArray.includes('no company')) {
      const allEmployeesOfCheckUser = Object.entries(choosedEmployees).reduce((val, el) => (el[0] === companyIdArray[0] ? el[1].data.list : val), '');
      if (!allEmployeesOfCheckUser || (allEmployeesOfCheckUser && allEmployeesOfCheckUser.length === 0)) {
        employeeSendRequestProps(
          {
            companyID: companyIdArray[0],
            listID: EMPLOYEE_LIST_ID,
            dataList: { listURL, params, transformResponse },
            field: 'employees'
          });
      }
    }
  };

  toggleSelection = (getOldState) => {
    const {
      selectedRows,
      monitoringReplaceSelected: monitoringReplaceSelectedProps
    } = this.props;

    const oldSelection = selectedRows.reduce((acc, el) => {
      acc[el] = true;
      return acc;
    }, {});

    const selection = getOldState(oldSelection);

    this.formAndSendRequest(Object.keys(selection));
    monitoringReplaceSelectedProps({ newSelected: Object.keys(selection) });
  };

  render() {
    const {
      myRoleType,
      modalWindowSend: sendModal,
      visibleWeb,
      selectedRows
    } = this.props;

    const sendSubmitChange = ({ ticketId, noteText }) => sendModal({
      modalID: EDIT_NOTE_MODAL_ID,
      requestConfig: {
        method: 'put',
        url: `${ERROR_MONITORING_TICKET_URL}/${ticketId}`,
        data: {
          note: noteText || ''
        }
      },
      cb: () => dataListReloadData({ listID: DATA_LIST_ID })
    });

    const accepRolesForNotifications = ['pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only'];

    return (
      <div className="main-table-container">
        <EditNoteWindow handleOnSubmit={sendSubmitChange} />
        <ChangeColumnsModalWindow dataListID={DATA_LIST_ID} />

        <TopBar updateButton showNavigation visibleWeb={visibleWeb} />

        <div className="container-fluid">
          <Breadcrumbs crumbs={[{ name: i18n.t('monitoring') }]} />

          {accepRolesForNotifications.includes(myRoleType) && (
            <div className="nav-monitoring">
              <SemiTabs />
            </div>
          )}
        </div>
        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30 px-0">
          <div className="col-xl-12 monitoring-top-bar px-0">
            <Changer myRoleType={myRoleType} />
            <MainMonitoringFilterV2 params={this.defaultParams} />
          </div>
        </div>
        <div className="container-fluid mx-0">
          <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <ReactTableV2
              listID={DATA_LIST_ID}
              listURL={ERROR_MONITORING_TICKETS_URL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              columns={this.state.columns}
              setRowSelection={this.toggleSelection}
              tableStyles={{ paddingBottom: 65 }}
              rowSelection={selectedRows.reduce((acc, el) => {
                acc[el] = true;
                return acc;
              }, {})}
              SubComponent={SubComponentWrapper}
            />
            <DataListPagination
              className="monitoring-table-pagination"
              Component={Pagination}
              listID={DATA_LIST_ID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Monitoring.propTypes = {
  modalWindowSend: PropTypes.func.isRequired,
  employeeSendRequest: PropTypes.func.isRequired,
  addChoosedCompany: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  saveAllDataListUsers: PropTypes.func.isRequired,
  allDataOnPage: PropTypes.instanceOf(Array),
  allData: PropTypes.instanceOf(Array),
  monitoringReplaceSelected: PropTypes.func.isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  choosedEmployees: PropTypes.instanceOf(Object),
  visibleWeb: PropTypes.bool,
  setMonitoringFilters: PropTypes.func.isRequired,
  pagesSettings: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = createStructuredSelector({
  companyUserId: companyUserIdSelector,
  selectedRows: getMonitoringSelectedRowsSelector,
  allDataOnPage: getDataListMonitoringDataSelector,
  allData: getMonitoringDataSelector,
  choosedEmployees: getMonitoringEmployeesSelector,
  visibleWeb: visibleWebSelector,
  oemId: getSignInUserOem_IdSelector,
  pagesSettings: getPagesSettingsSelector
});

export default connect(
  mapStateToProps,
  {
    addChoosedCompany,
    saveAllDataListUsers,
    monitoringReplaceSelected,
    modalWindowSend,
    employeeSendRequest,
    setMonitoringFilters
  }
)(Monitoring);

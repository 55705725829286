import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../api_helper';

export const DevicesTableApi = createApi({
  reducerPath: 'DevicesTableApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getCarBrandsList: builder.query({
      query: () => ({ url: '/sensors/car/brands', method: 'get' }),
      transformResponse: (data) => data.map((brand) => ({
        icon: brand.logo,
        value: brand.id,
        label: brand.name,
        authType: brand.authType
      }))
    }),
    carWebviewCode: builder.mutation({
      query: ({ data }) => ({ url: '/sensors/car/webviewCode', method: 'post', data })
    }),
    skiSearch: builder.query({
      query: ({ gatewayId }) => ({
        url: `/account/eebus/scan/${gatewayId}`,
        method: 'post',
        data: { type: 'carcharger', name: 'eebus' }
      })
    }),
    getDynamicPricesData: builder.query({
      query: ({ userId }) => {
        const params = new URLSearchParams({ resolutionMinutes: '15' });
        if (userId) params.append('userId', userId);
        return { url: `/user/settings/dynamic-prices-v2?${params.toString()}`, method: 'get' };
      },
      transformResponse: (data) => ({
        ...data,
        prices: data.prices.map((item) => [new Date(item.time).getTime(), item.price])
      })
    })
  })
});

export const {
  useGetCarBrandsListQuery,
  useCarWebviewCodeMutation,
  useSkiSearchQuery,
  useGetDynamicPricesDataQuery
} = DevicesTableApi;

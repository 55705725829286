import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { APP_ID, SOLAR_MANAGER_APP_ID, THREE_S_APP_ID, HELION_APP_ID } from './config';
import { EMPLOYEE_ROLE_TYPES } from './containers/UserDetails/constants';
import * as containers from './containers';
// eslint-disable-next-line no-unused-vars
import { AuthorizationApp, CheckAuthorization, OnlyGuests, ProtectedRoute } from './containers/Authorization/components';
import HomePage from './containers/Authorization/HomePage';

const SensorsOauth2 = React.lazy(() => import('./containers/SensorsOauth2'));
const PredictionChartError = React.lazy(() => import('./containers/PredictionChartError'));
const ZohoSupportPortal = React.lazy(() => import('./containers/ZohoSupportPortal'));
const Logout = React.lazy(() => import('./containers/Authorization/components/Logout'));
const FeedInManagement = React.lazy(() => import('./containers/FeedInManagement'));
const DashboardV2 = React.lazy(() => import('./containers/DashboardV2'));
const ProductionAnalysis = React.lazy(() => import('./containers/ProductionAnalysis'));
const InstallerDashboard = React.lazy(() => import('./containers/InstallerDashboard'));
const FlexDashboard = React.lazy(() => import('./containers/FlexDashboard'));
const HeatPumpMonitoring = React.lazy(() => import('./containers/HeatPumpMonitoring'));
const UserBySmId = React.lazy(() => import('./containers/UserBySmId'));

const flexDashboardRoutes = APP_ID === HELION_APP_ID ? [{
  path: '/flex-dashboard',
  exact: true,
  component: ProtectedRoute(FlexDashboard, ['root', 'solar_admin', 'pv_installer']),
  key: '#flexDashboard'
}] : [];

const authSSOBurkhalter = (APP_ID === SOLAR_MANAGER_APP_ID)
  ? [{
    path: '/auth/sso/burkhalter',
    component: containers.BurkhalterAuth,
    key: '#authSSOBurkhalter'
  }]
  : [];

const higherRoles = ['root', 'solar_admin', 'pv_installer', 'viewer', 'property_management', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES];

/**
 * List of all routes.
 */
const routes = [
  ...authSSOBurkhalter,
  {
    path: '/check-authorization',
    exact: true,
    component: CheckAuthorization(HomePage),
    key: '#check-authorization'
  },

  // Routes for WebView
  {
    path: '/mobile/powerFlowDetails/info/:jwt',
    exact: true,
    component: withTranslation()(containers.PowerFlowDetailsInfo),
    key: '#powerFlowDetails-info'
  },
  {
    path: '/dashboard-info',
    exact: true,
    component: withTranslation()(containers.DashboardInfo),
    key: '#dashboard-info'
  },
  {
    path: '/dashboard-app/:userID/:jwt/:groupPixelWidth?',
    exact: true,
    component: AuthorizationApp(DashboardV2, false, { disableAutoReload: true, disableURLParams: true }),
    key: '#dashboard-app'
  },
  {
    path: '/dashboard-app-v2/:userID/:jwt/:groupPixelWidth?',
    exact: true,
    component: AuthorizationApp(DashboardV2),
    key: '#dashboard-app-v2'
  },
  {
    path: '/prediction-chart/:jwt',
    exact: true,
    component: AuthorizationApp(containers.PredictionChart, ['end_user']),
    key: '#prediction'
  },
  {
    path: '/mobile/prediction-chart-error/:jwt',
    exact: true,
    component: AuthorizationApp(PredictionChartError, ['end_user']),
    key: '#predictionChartError'
  },

  // Private route
  {
    path: '/root-login',
    exact: true,
    component: ProtectedRoute(containers.RootSignIn, ['root']),
    key: '#rootLoginPage'
  },
  {
    path: '/maintenance',
    exact: true,
    component: containers.Maintenance,
    key: '#maintenance'
  },
  {
    path: '/login',
    exact: true,
    component: OnlyGuests(containers.SignIn),
    key: '#loginPage'
  },
  {
    path: '/oauth/authorize',
    exact: true,
    component: OnlyGuests(containers.OAuthSignIn),
    key: '#oauthLoginPage'
  },
  {
    path: '/basic/authorize',
    exact: true,
    component: OnlyGuests(containers.BasicAuthSignIn),
    key: '#basicAuthLoginPage'
  },
  {
    path: '/signup/:sm_id?',
    exact: true,
    component: OnlyGuests(containers.SignUp),
    key: '#signUpPage'
  },
  {
    path: '/verify/:verifyCode/',
    exact: true,
    component: OnlyGuests(containers.SignUp2),
    key: '#verifySignUpPage'
  },
  {
    path: '/verify/:app/:verifyCode/', // for mobile flow
    exact: true,
    component: OnlyGuests(containers.SignUp2),
    key: '#verifySignUpPage'
  },

  {
    path: '/forgot-password',
    exact: true,
    component: OnlyGuests(containers.ForgotPassForm),
    key: '#forgotPasswordPage'
  },
  {
    path: '/reset-password/:verifyCode/',
    exact: true,
    component: OnlyGuests(containers.ResetPassForm),
    key: '#resetPasswordPage'
  },
  {
    path: '/reset-password/:app/:verifyCode/', // for mobile flow
    exact: true,
    component: OnlyGuests(containers.ResetPassForm),
    key: '#resetPasswordPage'
  },
  {
    path: '/email-verify/:verifyCode/',
    exact: true,
    component: containers.VerifyChangeEmail,
    key: '#changeEmailVerifyPage'
  },
  {
    // for mobile flow
    path: '/email-verify/:app/:verifyCode/',
    exact: true,
    component: containers.VerifyChangeEmail,
    key: '#changeEmailVerifyPageWithType'
  },
  {
    path: '/my-devices/',
    exact: true,
    component: ProtectedRoute(containers.UserDetails, ['end_user']),
    key: '#myDevicesPage'
  },
  {
    path: '/dashboard/',
    exact: true,
    strict: true,
    component: ProtectedRoute(DashboardV2, ['end_user']),
    key: '#dashboardPage'
  },
  {
    path: '/setting-profile/',
    exact: true,
    strict: true,
    component: ProtectedRoute(containers.SettingProfile),
    key: '#settingProfilePage'
  },
  {
    path: '/setting-profile/:roleLink/:userID',
    exact: true,
    component: ProtectedRoute(
      containers.SettingProfile,
      higherRoles
    ),
    key: '#settingProfileUserPage'
  },
  {
    path: '/users',
    exact: true,
    component: ProtectedRoute(
      containers.Users,
      higherRoles
    ),
    key: '#usersPage'
  },
  {
    path: '/sub-users',
    exact: true,
    component: ProtectedRoute(
      containers.SubUsers,
      ['root', 'solar_admin', 'pv_installer', 'viewer', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES]
    ),
    key: '#usersPage'
  },
  {
    path: '/users/invited-users',
    exact: true,
    component: ProtectedRoute(containers.InvitedUsers),
    key: '#invited'
  },
  {
    path: '/users/:userID', // user devices
    exact: true,
    component: ProtectedRoute(
      containers.UserDetails,
      higherRoles
    ),
    key: '#userViewPage'
  },
  {
    path: '/endUserBySMID/:sm_id',
    exact: true,
    component: ProtectedRoute(UserBySmId, higherRoles),
    key: '#endUserBySMID'
  },

  {
    path: '/dashboard/:userID',
    exact: true,
    component: ProtectedRoute(
      DashboardV2,
      higherRoles
    ),
    key: '#dasboardUserPage'
  },
  {
    path: '/pv-installers',
    exact: true,
    component: ProtectedRoute(
      containers.PvInstallers,
      ['root', 'solar_admin', 'viewer', 'oem', 'oem_employee', 'oem_employee_read_only']
    ),
    key: '#pvInstallersPage'
  },
  {
    path: '/oem',
    exact: true,
    component: ProtectedRoute(
      containers.OEM,
      ['root', 'solar_admin', 'viewer']
    ),
    key: '#oemPage'
  },
  {
    path: '/oem-employees',
    exact: true,
    component: ProtectedRoute(
      containers.OEMEmployees,
      ['oem']
    ),
    key: '#oemEmployeesPage'
  },
  {
    path: '/pv-installers/:companyID',
    exact: true,
    component: ProtectedRoute(
      containers.CompanyDetails,
      ['root', 'solar_admin', 'viewer', 'oem', 'oem_employee', 'oem_employee_read_only']
    ),
    key: '#pvInstallersViewPage'
  },
  {
    path: '/oem/:companyID',
    exact: true,
    component: ProtectedRoute(
      containers.PVInstallersDetails,
      ['root', 'solar_admin', 'viewer']
    ),
    key: '#OEMViewPage'
  },
  {
    path: '/property-managements',
    exact: true,
    component: ProtectedRoute(containers.PropMgmts, ['root', 'solar_admin', 'oem']),
    key: '#propMgmtsPage'
  },
  {
    path: '/property-managements/:propMgmtID',
    exact: true,
    component: ProtectedRoute(containers.PropMgmtDetails, ['root', 'solar_admin', 'oem']),
    key: '#propMgmtsViewPage'
  },
  {
    path: '/admins',
    exact: true,
    component: ProtectedRoute(containers.Admins, ['root']),
    key: '#adminsPage'
  },
  {
    path: '/viewers',
    exact: true,
    component: ProtectedRoute(containers.Viewers, ['root', 'solar_admin']),
    key: '#viewersPage'
  },
  {
    path: '/viewers/:viewerID',
    exact: true,
    component: ProtectedRoute(containers.ViewersDetails, ['root', 'solar_admin']),
    key: '#viewersViewPage'
  },
  {
    path: '/installed-sm',
    exact: true,
    component: ProtectedRoute(containers.InstalledSM, ['root', 'solar_admin']),
    key: '#installedSMPage'
  },
  {
    path: '/monitoring/notification',
    exact: true,
    component: ProtectedRoute(
      containers.MonitoringNotification,
      ['pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES]
    ),
    key: '#monitoringPage'
  },
  {
    path: '/monitoring',
    exact: true,
    component: ProtectedRoute(
      containers.Monitoring,
      ['root', 'solar_admin', 'property_management', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES]
    ),
    key: '#monitoringPage'
  },
  {
    path: '/employees',
    exact: true,
    component: ProtectedRoute(containers.Employees, ['pv_installer']),
    key: '#employeesPage'
  },
  {
    path: '/load-management-dashboard/',
    exact: true,
    strict: true,
    component: ProtectedRoute(containers.LoadManagementDashboard, ['end_user']),
    key: '#loadManagementDashboardPage'
  },
  {
    path: '/load-management-dashboard/:userId',
    exact: true,
    component: ProtectedRoute(
      containers.LoadManagementDashboard,
      higherRoles
    ),
    key: '#loadManagementDashboard'
  },
  {
    path: '/no-access',
    exact: true,
    component: ProtectedRoute(containers.NoAccess, ['property_management', 'viewer']),
    key: '#noAccessPage'
  },
  {
    path: '/sensor/auth/oauth2',
    exact: true,
    component: ProtectedRoute(
      SensorsOauth2,
      ['root', 'solar_admin', 'property_management', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', 'end_user', ...EMPLOYEE_ROLE_TYPES]
    ),
    key: '#sensorAuthOauth2'
  },
  {
    path: '/zoho-support-portal',
    exact: true,
    component: ProtectedRoute(ZohoSupportPortal),
    key: '#zohoSupportPortal'
  },
  {
    path: '/overview',
    exact: true,
    component: ProtectedRoute(
      containers.Overview,
      higherRoles
    ),
    key: '#overview'
  },
  {
    path: '/documentation/:userId',
    exact: true,
    component: ProtectedRoute(containers.Documentation),
    key: '#documentation'
  },
  {
    path: '/payments',
    exact: true,
    component: containers.PaymentsAuthorization(containers.Payments, ['end_user'], { repeat: true }),
    key: '#payments'
  },
  {
    path: '/logout',
    exact: true,
    component: () => (
      <Suspense>
        <Logout />
      </Suspense>
    ),
    key: '#logout'
  },
  {
    path: '/heat-pump-monitoring/:userID',
    exact: true,
    component: ProtectedRoute(
      HeatPumpMonitoring,
      ['root', 'solar_admin', 'pv_installer', 'property_management', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES]
    ),
    key: '#heatPumpMonitoring'
  },
  // {
  //   path: '/heat-pump-monitoring/',
  //   exact: true,
  //   strict: true,
  //   component: ProtectedRoute(HeatPumpMonitoring, ['end_user']),
  //   key: '#heatPumpMonitoringPage'
  // },
  {
    path: '/feed-in-management/create/:userId',
    exact: true,
    component: ProtectedRoute(FeedInManagement, ['root', 'solar_admin', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', 'property_management', 'pv_installer_employee', 'pv_installer_employee_read_install'], { type: 'create' }),
    key: '#feedInManagementCreate'
  },
  {
    path: '/feed-in-management/edit/:userId/:feedInId',
    exact: true,
    component: ProtectedRoute(FeedInManagement, ['root', 'solar_admin', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', 'property_management', 'pv_installer_employee', 'pv_installer_employee_read_install'], { type: 'edit' }),
    key: '#feedInManagementEdit'
  },
  ...([THREE_S_APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID].includes(APP_ID) ? [{
    exact: true,
    path: '/production-analysis/:key?/:userId?',
    component: ProtectedRoute(ProductionAnalysis, ['root', 'solar_admin', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', 'property_management', ...EMPLOYEE_ROLE_TYPES]),
    key: '#productionAnalysis'
  }] : []),

  ...(APP_ID === SOLAR_MANAGER_APP_ID ? [{
    exact: true,
    path: '/installer-dashboard',
    component: ProtectedRoute(InstallerDashboard, null, {}, [{ field: 'isInstallationsMetricsAllowed', value: true }]),
    key: '#installerDashboard'
  }] : []),
  ...flexDashboardRoutes,
  {
    path: '/',
    exact: true,
    component: ProtectedRoute(HomePage),
    key: '#homePage'
  },
  {
    component: containers.NotFound,
    key: '#notFoundPage'
  }
];

export default routes;

import { EMPLOYEE_ROLE_TYPES } from '../../../containers/UserDetails/constants';
import { APP_ID, THREE_S_APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../config';

const allowedRolesForProductionAnalysisObjMap = {
  [THREE_S_APP_ID]: ['root', 'solar_admin', 'pv_installer', 'oem', 'property_management', 'viewer', ...EMPLOYEE_ROLE_TYPES],
  [HELION_APP_ID]: ['root', 'solar_admin', 'pv_installer', ...EMPLOYEE_ROLE_TYPES],
  [SOLAR_MANAGER_APP_ID]: ['root', 'solar_admin']
};

/**
   * Array of all tabs and role types
   * @constant
   * @memberof module:TopBar
   */
const tabsByRoles = ({ user }) => [
  {
    label: 'overview',
    link: '/overview',
    allowedRoles: ['root', 'solar_admin', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', 'property_management', 'viewer', ...EMPLOYEE_ROLE_TYPES]
  },
  {
    label: 'SMUs',
    link: '/users',
    allowedRoles: ['root', 'solar_admin', 'pv_installer', 'property_management', 'viewer', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES]
  },
  ...(user.role.type === 'viewer' && !user.tabs?.sub_users) ? [] : [{
    label: 'subSMUs',
    link: '/sub-users',
    allowedRoles: ['root', 'solar_admin', 'pv_installer', 'viewer', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES]
  }],
  {
    label: 'employees',
    link: '/employees',
    allowedRoles: ['pv_installer']
  },
  ...(user.role.type === 'viewer' && !user.tabs?.installers) ? [] : [{
    label: 'pvInstallers',
    link: '/pv-installers',
    allowedRoles: ['root', 'solar_admin', 'viewer', 'oem', 'oem_employee', 'oem_employee_read_only']
  }],
  ...(user.role.type === 'viewer' && !user.tabs?.oem) ? [] : [{
    label: 'oem',
    link: '/oem',
    allowedRoles: ['root', 'solar_admin', 'viewer']
  }],
  ...(user.role.type === 'oem') ? [{
    label: 'employees',
    link: '/oem-employees',
    allowedRoles: ['oem']
  }] : [],
  {
    label: 'propMgmt',
    link: '/property-managements',
    allowedRoles: ['root', 'solar_admin', 'oem']
  },
  {
    label: 'viewers',
    link: '/viewers',
    allowedRoles: ['root', 'solar_admin']
  },
  {
    label: 'admins',
    link: '/admins',
    allowedRoles: ['root']
  },
  {
    label: 'installedSM',
    link: '/installed-sm',
    allowedRoles: ['root', 'solar_admin']
  },
  {
    label: 'monitoring',
    link: '/monitoring',
    allowedRoles: ['root', 'solar_admin', 'property_management', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only', ...EMPLOYEE_ROLE_TYPES]
  },
  ...([THREE_S_APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID].includes(APP_ID) ? [{
    label: 'productionAnalysis',
    link: '/production-analysis',
    allowedRoles: allowedRolesForProductionAnalysisObjMap[APP_ID] || []
  }] : []),
  ...(user.isInstallationsMetricsAllowed && APP_ID === SOLAR_MANAGER_APP_ID ? [{
    label: 'growthMetrics',
    link: '/installer-dashboard',
    allowedRoles: ['root', 'solar_admin', 'property_management']
  }] : []),
  ...(APP_ID === HELION_APP_ID
    ? [{
      label: 'flex',
      link: '/flex-dashboard',
      allowedRoles: ['root', 'solar_admin', 'pv_installer']
    }] : [])
];

export default tabsByRoles;

import { pick } from 'lodash';
import { formatDataByDeviceType } from '../../../utils';
import fieldsForDevices from './fieldsForDevices';
import { getUpdatedFieldList } from './getUpdatedFieldList';

const transformFieldsForAPI = (fields, type) => {
  const newFields = JSON.parse(JSON.stringify(fields));

  const allowedDataFields = getUpdatedFieldList(newFields.device_group, newFields.data);

  // we have another flow for loadguards
  if (type !== 'loadguards') {
    // subMeterCostTypes - unique field for sub-meter
    newFields.data = pick(newFields.data, [allowedDataFields, 'subMeterCostTypes'].flat());
  }

  if (newFields.data?.ip) {
    if (fieldsForDevices[newFields.device_group]?.includes('ip')) {
      newFields.ip = newFields.data.ip;
    }
    delete newFields.data.ip;
  }

  if (newFields.createNewTag) {
    if (newFields.createNewTag === newFields.tag) {
      newFields.createNewTag = true;
    } else {
      delete newFields.createNewTag;
    }
  }

  if ('isLoadGuardConfigured' in newFields) {
    newFields.isLoadGuardConfigured = undefined;
  }

  if (newFields.data?.User) {
    newFields.data.User = newFields.data.User.trim();
  }

  return newFields?.data
    ? { ...newFields, data: formatDataByDeviceType(newFields.data) }
    : newFields;
};

export default transformFieldsForAPI;

import moment from 'moment-timezone';

export const chartChangeRange = (from, to, replaceFromProps, locationPathname) => {
  const min = moment(from).toISOString();
  const max = moment(to).toISOString();

  const newSearch = { from: min, to: max, subType: 'range' };
  replaceFromProps(`${locationPathname}?${new URLSearchParams(newSearch).toString()}`);
};

export const generateRequestQuery = (from, to, type) => {
  const objMap = {
    t: () => ({ from: moment().startOf('day').toISOString(), to: moment().toISOString() }),
    h: () => ({ from: moment().subtract(1, 'hour').toISOString(), to: moment().toISOString() }),
    d: () => ({ from: moment().subtract(1, 'day').toISOString(), to: moment().toISOString() }),
    w: () => ({ from: moment().subtract(1, 'week').toISOString(), to: moment().toISOString() })
  };

  const range = { from, to, type };

  if (!range.from || !range.to) {
    range.from = objMap[type || 't']?.().from;
    range.to = objMap[type || 't']?.().to;
  }

  if (!range.type) {
    if (!from || !to) {
      range.type = 't';
    } else {
      const hoursDiff = moment(range.to).diff(moment(range.from), 'hours');

      if (hoursDiff > 24) {
        range.type = 'w';
      }

      if (hoursDiff <= 24) {
        range.type = 'd';
      }

      if (hoursDiff <= 1) {
        range.type = 'h';
      }
    }
  }

  range.scaleMs = moment(range.to).diff(moment(range.from), 'ms');

  return range;
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumbs, TopBar } from '../../components';
import i18n from '../../i18n';
import ProductionStatistics from './components/ProductionStatistics';
import PlantsData from './components/PlantsData';
import OverviewPieCharts from './components/PieChart';
import CircleChart from './components/CircleChart';
import Widget from './components/Widget';
import { visibleWebSelector } from '../Users/selectors';
import { requestOverviewData } from './slice';
import { getOverviewSelector } from '../../redux-store/selectors/overview';

import './styles/index.v2.scss';

const Overview = () => {
  const dispatch = useDispatch();

  const overviewData = useSelector(getOverviewSelector);
  const visibleWeb = useSelector(visibleWebSelector);

  const {
    production,
    plants,
    supportContracts,
    monitoring,
    status,
    consumption,
    autarchy,
    totalEnergy
  } = overviewData;

  const { carChargers, heatpumps, waterHeaters, v2xChargers } = totalEnergy || {};

  useEffect(() => {
    dispatch(requestOverviewData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopBar showNavigation visibleWeb={visibleWeb} />
      <div className="container-fluid overview">
        <Breadcrumbs breadcrumbsClass="subheader-overview" crumbs={[{ name: i18n.t('overview') }]} />

        <div className="overview-wrapper">
          <div className="overview-left-part">
            <ProductionStatistics productionData={production} />
            <PlantsData plants={plants} supportContracts={supportContracts} />
            <OverviewPieCharts monitoring={monitoring} status={status} />
          </div>
          <div className="overview-right-part">
            <CircleChart chartData={consumption} titleKey="overallSelfConsumption" />
            <CircleChart chartData={autarchy} titleKey="overallAutarchy" />

            <Widget widgetData={carChargers} deviceType="carCharger" />
            <Widget widgetData={waterHeaters} deviceType="waterHeater" />

            <Widget widgetData={heatpumps} deviceType="heatpump" />
            <Widget widgetData={v2xChargers} deviceType="v2x" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;

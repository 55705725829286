import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const LoadManagementCurrentValues = ({ houseFuse, smartMeterData }) => (
  <div className="loadManagementCurrentValues-container">
    <div>
      <span className="label">{i18n.t('houseFuseLimit')}</span>
      <div className="houseFuse">
        <span className="value phase-general">{houseFuse}</span>
        <span className="value-a">A</span>
      </div>
    </div>
    <div>
      <span className="label">{i18n.t('phase1')}</span>
      <div className="phase1">
        {smartMeterData?.currentL1 ? (
          <>
            <span className="value phase-general">{smartMeterData?.currentL1}</span>
            <span className="value-a">A</span>
          </>
        ) : (
          <span className="value-minus">-</span>
        )}
      </div>
    </div>
    <div>
      <span className="label">{i18n.t('phase2')}</span>
      <div className="phase2">
        {smartMeterData?.currentL2 ? (
          <>
            <span className="value phase-general">{smartMeterData?.currentL2}</span>
            <span className="value-a">A</span>
          </>
        ) : (
          <span className="value-minus">-</span>
        )}
      </div>
    </div>
    <div>
      <span className="label">{i18n.t('phase3')}</span>
      <div className="phase3">
        {smartMeterData?.currentL3 ? (
          <>
            <span className="value phase-general">{smartMeterData?.currentL3}</span>
            <span className="value-a">A</span>
          </>
        ) : (
          <span className="value-minus">-</span>
        )}
      </div>
    </div>
  </div>
);

export default LoadManagementCurrentValues;

LoadManagementCurrentValues.propTypes = {
  houseFuse: PropTypes.number.isRequired,
  smartMeterData: PropTypes.instanceOf(Object).isRequired
};

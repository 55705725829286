import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSystems: []
};

const productionAnalysisSlice = createSlice({
  name: 'productionAnalysis',
  initialState,
  reducers: {
    setSelectedSystems: (state, action) => {
      state.selectedSystems = action.payload.selectedSystems;
    }
  }
});

export const {
  setSelectedSystems
} = productionAnalysisSlice.actions;

export default productionAnalysisSlice.reducer;

/**
 * Array of languages to select in guests pages
 * @ignore
 */
export const options = [
  {
    value: 'english',
    label: 'English'
  },
  {
    value: 'deutsch_ch',
    label: 'Deutsch (Schweiz)'
  },
  {
    value: 'deutsch_de',
    label: 'Deutsch (Deutschland)'
  },
  {
    value: 'french',
    label: 'French'
  },
  {
    value: 'italian',
    label: 'Italian'
  },
  {
    value: 'danish',
    label: 'Danish'
  },
  {
    value: 'dutch',
    label: 'Dutch'
  }
];

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedInUser: undefined,
  userForView: undefined,
  loadingLoggedInUser: false,
  loadingUserForView: false,
  error: null,
  success: null
};

const userServiceSlice = createSlice({
  name: 'userServiceSlice',
  initialState,
  reducers: {
    fetchLoggedInUserSuccess(state, action) {
      state.loadingLoggedInUser = false;
      state.loggedInUser = action.payload;
    },
    fetchUserForViewSuccess(state, action) {
      state.loadingUserForView = false;
      state.userForView = action.payload;
    }
  }
});

export const { fetchLoggedInUserSuccess, fetchUserForViewSuccess } = userServiceSlice.actions;

export default userServiceSlice.reducer;

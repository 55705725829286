import { createApi } from '@reduxjs/toolkit/query/react';

import moment from 'moment';
import { axiosBaseQuery } from '../../api_helper';
import { addNotification } from '../../containers/NotificationGenerator/slice';
import { DATA_SENSORS_LOAD_DATA_URL } from '../../api/apiUrls';
import { updateChartData } from './slice';

export const loadManagementApi = createApi({
  reducerPath: 'loadManagementApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    getLoadManagementData: builder.query({
      providesTags: ['loadManagementApi'],
      query: ({ gatewayId, from, to }) => {
        const url = `${DATA_SENSORS_LOAD_DATA_URL}?gatewayId=${gatewayId}&from=${from}&to=${to}`;

        return { url, method: 'get' };
      },

      onQueryStarted: async (_queryArgs, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      },
      transformResponse: (response, _meta, arg) => ({
        originalArg: arg,
        data: response
      })
    }),
    getRealtimeUpdate: builder.query({
      providesTags: ['loadManagementApi'],
      query: ({ gatewayId, onlySmartMeter, from, interval }) => {
        const queries = { gatewayId, onlySmartMeter, from, interval };

        const url = `${DATA_SENSORS_LOAD_DATA_URL}?${new URLSearchParams(queries)}`;

        return { url, method: 'get' };
      },
      onQueryStarted: async (queryArgs, { queryFulfilled, dispatch, getState }) => {
        try {
          const { data } = await queryFulfilled;
          const { loadManagementServiceSlice } = getState();
          if (queryArgs?.onlySmartMeter || loadManagementServiceSlice.type !== queryArgs.type) {
            dispatch(updateChartData({ chartData: { smartMeter: data.smartMeter } }));
            return;
          }

          dispatch(updateChartData({
            chartData: {
              smartMeter: data.smartMeter,
              L1: data.L1,
              L2: data.L2,
              L3: data.L3
            },
            realTimeData: {
              from: loadManagementServiceSlice.type === 't'
                ? loadManagementServiceSlice.from
                : moment().subtract(queryArgs.scaleMs, 'ms').toISOString(),
              to: moment().toISOString()
            }
          }));
        } catch (error) {
          dispatch(addNotification({ type: 'error', text: error.message }));
        }
      }
    })
  })
});

export const {
  useGetLoadManagementDataQuery,
  useLazyGetRealtimeUpdateQuery
} = loadManagementApi;

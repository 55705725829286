import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { reduxForm, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { CancelButton } from '../../../components/UIKit';
import { getSkiSearchModalSelector } from '../../../redux-store/selectors/modals';
import { Preloader } from '../../../components';
import { closeModalWindow, SKI_SEARCH_MODAL_ID } from '../../ModalWindow/slice';
import { useSkiSearchQuery } from '../services';
import i18n from '../../../i18n';

const SkiSearchWindow = (props) => {
  const { reset } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getSkiSearchModalSelector);
  const { gatewayId, form: formID, deviceType } = modal?.data ?? {};

  const { isLoading, isFetching, data: skiSearchData = [] } = useSkiSearchQuery({ gatewayId }, { skip: !modal?.opened });
  const handleOnClose = () => dispatch(closeModalWindow({ modalID: SKI_SEARCH_MODAL_ID }));

  const onClickHandler = (ski) => {
    if (formID) {
      dispatch(change(formID, 'data.ski', ski));
    }
    handleOnClose();
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow isModalOver modalID={SKI_SEARCH_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('selectDeviceType', { type: i18n.t(deviceType) })}</h5>
      </div>
      <div className={`modal-body ${isLoading ? 'm-0' : ''}`}>
        {(isLoading || isFetching) ? <Preloader /> : null}
        {!isLoading && !isFetching && Boolean(skiSearchData.length) && (
          <ul className="ip-list">
            {skiSearchData.map(({ brand, ski }) => (
              <li
                key={uuidv4()}
                onClick={onClickHandler.bind(null, ski)}
                className="nav-item m-tabs__item ip-item"
              >
                <span>
                  <a className="nav-link m-tabs__link ip-link">
                    <span className="ip-name">{brand}</span>
                    <br />
                    <span className="ip-value">ski: &nbsp;</span>
                    {ski}
                  </a>
                </span>
              </li>
            ))}
          </ul>
        )}

        {!isLoading && !isFetching && !skiSearchData.length && (
          <p className="noChargingFound">{i18n.t('noDeviceTypeFound', { type: i18n.t(deviceType) })}</p>
        )}
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleOnClose} />
      </div>
    </ModalWindow>
  );
};

SkiSearchWindow.propTypes = {
  reset: PropTypes.func.isRequired
};

const form = reduxForm({
  form: 'skiSearchForm'
})(SkiSearchWindow);

export default form;

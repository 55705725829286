import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import locales from './locales';
import i18n from '../../i18n';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';

import './styles.scss';

//! REFACTOR: use moment.js instead of dayjs
moment.updateLocale('en', { week: { dow: 1 } });
moment.updateLocale('de', { week: { dow: 1 } });
moment.updateLocale('it', { week: { dow: 1 } });
moment.updateLocale('fr', { week: { dow: 1 } });
moment.updateLocale('nl', { week: { dow: 1 } });

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });
dayjs.updateLocale('de', { weekStart: 1 });
dayjs.updateLocale('it', { weekStart: 1 });
dayjs.updateLocale('fr', { weekStart: 1 });
dayjs.updateLocale('nl', { weekStart: 1 });

/**
 * Renders calendar picker
 * @memberof module:UsersTable
 */
const CustomDatePicker = (props) => {
  const { handleChange, value = dayjs(), maxDetail = 'date', disabledDate, className = '', validateDate } = props;
  const [timestamp, setTimestamp] = useState(dayjs(value).isValid() ? dayjs(value).add(1, 'ms') : dayjs());

  const datePickerRef = useRef();
  /**
   * Defines if date is valid or not
   * @param {Moment} current - dayjs date object
   * @returns {boolean}
   */
  const isValidDate = (current) => current.isAfter(dayjs());

  /**
   * Returns html element - container of the floating layer of the datepicker,
   * while the default is to create a div element in body.
   * @returns {Object} - Node
   */
  const getCustomDatepickerContainerNode = () => datePickerRef?.current;

  const setWeekLabel = () => {
    const emptyCellByAriaLabel = datePickerRef.current?.querySelector('th[aria-label="empty cell"]');

    if (emptyCellByAriaLabel) {
      emptyCellByAriaLabel.innerHTML = i18n.t('week');
      emptyCellByAriaLabel.style.fontSize = '14px';
      emptyCellByAriaLabel.style.borderRight = '3px solid #CCE9DE';
    }
  };

  useEffect(() => {
    const nextValue = dayjs(value);
    if (nextValue.isValid()) {
      if (typeof validateDate === 'function') {
        if (validateDate(nextValue)) {
          setTimestamp(nextValue);
        }
      } else {
        setTimestamp(nextValue);
      }
    }
  }, [value, validateDate]);

  return (
    <div ref={datePickerRef} className={`custom-datepicker-container ${className}`}>
      <ConfigProvider locale={locales?.[i18n.language] || locales.english}>
        <DatePicker
          value={timestamp}
          onChange={(date) => handleChange(date.format('YYYY-MM-DD'))}
          open
          getPopupContainer={getCustomDatepickerContainerNode}
          popupStyle={{
            position: 'relative',
            opacity: 1,
            pointerEvents: 'all'
          }}
          style={{
            /**
             * Hide input field
             */
            // display: 'none'
          }}
          onOpenChange={setWeekLabel}
          onPanelChange={() => new Promise((resolve) => {
            setTimeout(() => {
              setWeekLabel();
              resolve();
            }, 0);
          }
          )}
          className="custom-antd-datepicker"
          disabledDate={disabledDate || isValidDate}
          picker={maxDetail}
          showToday={false}
          prevIcon={<>&#8249;</>} // ‹
          superPrevIcon={<>&#171;</>} // «
          nextIcon={<>&#8250;</>} // ›
          superNextIcon={<>&#187;</>} // »
        />
      </ConfigProvider>
    </div>
  );
};

CustomDatePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.number,
    PropTypes.string
  ]),
  maxDetail: PropTypes.string,
  disabledDate: PropTypes.func,
  className: PropTypes.string,
  validateDate: PropTypes.func
};

export default CustomDatePicker;
